import whatsappIcon from '../../assets/whatsapp-icon.svg'
import React from "react";
import {MESSAGES} from "../../dictionary/menuItems";
export default function WhatsAppLink() {
  const handleClick = () => {
    const url = `${MESSAGES.WHATS_APP_URL}${MESSAGES.WHATS_APP_MESSAGE_TEXT}`;
    window.open(url, "_blank"); // Open in a new tab
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-20 left-4 z-100 flex items-center justify-center p-3 rounded-full shadow-lg hover:bg-gray-400 hover:opacity-100 opacity-85 transition duration-300"
    >
      <img src={whatsappIcon} alt="Logo" className="h-8 w-8" />
    </button>
  );
};

