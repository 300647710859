import { MENU_ITEMS } from "../../dictionary/menuItems";

export const menuItems = [
  {
    to: '/#contactus',
    type: 'section',
    label: MENU_ITEMS.CONTACT_US
  },
  {
    to: '/#products',
    type: 'section',
    label: MENU_ITEMS.PRODUCTS
  },
  {
    to: '/#numerology',
    type: 'section',
    label: MENU_ITEMS.NUMEROLOGY
  },
  {
    to: '/#about',
    type: 'section',
    label: MENU_ITEMS.ABOUT
  },
  {
    to: '/#homepage',
    type: 'section',
    label: MENU_ITEMS.HOME_PAGE
  },
];