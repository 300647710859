// HamburgerIcon.jsx
import React from 'react';

const HamburgerIcon = ({ isOpen }) => {
  return (
    <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
      {isOpen ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.364 6.364a1 1 0 00-1.414 0L12 11.314l-4.95-4.95a1 1 0 10-1.414 1.414L10.586 12l-4.95 4.95a1 1 0 001.414 1.414L12 12.686l4.95 4.95a1 1 0 001.414-1.414L13.414 12l4.95-4.95a1 1 0 000-1.414z"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5h16a1 1 0 110 2H4a1 1 0 010-2zm0 6h16a1 1 0 110 2H4a1 1 0 010-2zm0 6h16a1 1 0 110 2H4a1 1 0 010-2z"
        />
      )}
    </svg>
  );
};

export default HamburgerIcon;
