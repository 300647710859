import React from 'react';
import { WORDS } from "../../dictionary/words";
import Accordion from "../../components/Accordion";
export default function Numerology3() {
  return (
    <div id="numerology3" className="flex items-center justify-center p-1 min-h-screen h-screen flex-wrap border-t border-gray-300">
          <Accordion data={WORDS.NUMEROLOGY_3}  />
    </div>
  )
}
