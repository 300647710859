import React from 'react';
import nataliaImage from "../../assets/images/natalia-image.png";
import tree87opaqueSymbol from '../../assets/images/tree87opaqueSymbol.png'
import { WORDS } from '../../dictionary/words';

export default function About() {
  return (
    <div id="about" className="flex flex-col md:flex-row min-h-screen">
      <div className="flex flex-col justify-center md:w-3/5 md:pr-16 p-4 text-black">
        <div>
          <div className="text-2xl mb-2 font-lia-shamaim">{WORDS.ABOUT.NICE_TO_MEET_YOU}</div>
          <img src={tree87opaqueSymbol} alt="tree87 logo" className="absolute md:scale-160 md:mr-140px mr-2"/>
        </div>
        <div className="text-base md:text-2xl mb-2  sm:mt-16">
          <span className="text-base md:text-2xl ml-1 font-polin-bold">{WORDS.ABOUT.MY_NAME_IS_TITLE}</span>
          {WORDS.ABOUT.MY_NAME_IS}
        </div>
        <div className="text-base md:text-2xl mb-2">
          <span className="text-base md:text-2xl ml-1 font-polin-bold">{WORDS.ABOUT.MY_DESIGNATION_TITLE}</span>
          {WORDS.ABOUT.MY_DESIGNATION}
        </div>
        <div className="text-base md:text-2xl mb-2">
          <span className="text-base md:text-2xl ml-1 font-polin-bold">{WORDS.ABOUT.NUMEROLOGY_THEORY_TITLE}</span>
          {WORDS.ABOUT.NUMEROLOGY_THEORY}
        </div>
        <div className="text-base md:text-2xl mb-2">
          <span className="text-base md:text-2xl ml-1 font-polin-bold">{WORDS.ABOUT.HELPS_WITH_TITLE}</span>
          {WORDS.ABOUT.HELPS_WITH}
        </div>
      </div>
      <div className="flex justify-center items-center md:w-2/5">
        <img src={nataliaImage} alt="Natalia numerologist" className="w-4/5"/>
      </div>
    </div>
  )
}
