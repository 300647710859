import React, {useState} from 'react';
import AccordionItem from "./AccordionItem";
import './accordion.css';
//https://codepen.io/Mavel-Creative/pen/mdLVGGM
const Accordion = ({ data }) => {
  const [active, setActive] = useState(1);
  const { MESSAGES, TITLE} = data;

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  }

  return (
    <>
      <div className="container-fluid mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card">
              <div className="card-body">
                <div className="form-heading mb-4 text-primary text-center mt-3 font-lia-shamaim text-xl">{ TITLE }</div>
                {MESSAGES.map((messages, index) => {
                  return (
                    <AccordionItem key={index} active={active} handleToggle={handleToggle} messages={messages} />
                  )
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
