import React from 'react';
import background from "../../assets/tree87-bg-smooth.jpg";
import tree87InBorder from '../../assets/tree87InBorder.png'
import { WORDS } from '../../dictionary/words';
export default function Header() {

  return (
    <section id="homepage" className="relative min-h-screen h-screen w-full">
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat"
           style={{ backgroundImage: `url(${background})` }}></div>
      <div className="relative z-10 flex justify-center items-center h-full">
        <img
          src={tree87InBorder}
          alt="Overlay"

          className="absolute transform scale-160 w-1/2 max-w-xs
          md:max-w-sm lg:max-w-md xl:max-w-lg
          lg:scale-100 xl:scale-120 "
        />
        <div  className="flex items-center justify-center min-h-screen text-center flex-col font-polin-light">
          <div className="relative z-10 flex justify-center items-center
                font-lia-shamaim transform text-3xl"
              >
            {WORDS.NATALIA_FULL_NAME}
          </div>
          <div className="relative z-10 flex text-xl justify-center items-center h-full mt-4 transform">
            {WORDS.KABALISTIC_NUMEROLOGIST}
          </div>
        </div>
      </div>
    </section>
  );
}
