import React, {useState} from 'react';
import background from "../../assets/tree87-bg-smooth.jpg";
import designerLogo from '../../assets/images/eden_jarmon.png';
import whatsappIcon from '../../assets/whatsapp-icon.svg'
import { WORDS } from "../../dictionary/words";
import {MESSAGES} from "../../dictionary/menuItems";

export default function ContactUs() {

  const initialFormFields =
    WORDS.CONTACT_US.FORM.LIST_OF_FIELDS.reduce((final, { ID }) => {
        final[ID] = '';
        return final;
      }, {});
  const [formValues, setFormValues] = useState({
    ...initialFormFields
  });
  function onClick() {
    const { first_name, last_name } = formValues;
    console.log('clicked 1', formValues);
    const message =
      WORDS.CONTACT_US.FORM.FULL_NAME_MESSAGE(first_name, last_name);

    console.log('clicked', formValues, message);

    const url = `${MESSAGES.WHATS_APP_URL}${message}`;
    window.open(url, "_blank"); // Open in a new tab
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    <section id="contactus" className="relative min-h-screen h-screen">
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat"
           style={{ backgroundImage: `url(${background})` }}></div>
      <div className="flex flex-col justify-end min-h-screen">
        <div className="font-lia-shamaim text-white text-md md:text-4xl z-10 mr-4">{WORDS.CONTACT_US.TITLE}</div>
        <div
          className="relative z-10 flex flex-row justify-right items-center w-8/9 bg-opacity-15 rounded-l-3xl">
          <div className="p-10 rounded-tl-lg rounded-bl-lg shadow-md w-full max-w-5xl">
            <div className="flex flex-col md:flex-row md:items-center gap-4">
              {
                WORDS.CONTACT_US.FORM.LIST_OF_FIELDS.map(({ ID, PLACEHOLDER }) => (
                  <div className="mb-2 text-black" key={ID}>
                    <input
                      type="text"
                      id={ID}
                      name={ID}
                      onChange={handleChange}
                      placeholder={PLACEHOLDER}
                      className="block w-full md:flex-1 p-2 border border-gray-300 m-auto "
                    />
                  </div>
                ))
              }
              <div className="mb-2 md:w-1/4">
                <button
                  type="submit"
                  onClick={onClick}
                  className="w-full h-12 flex flex-row items-center justify-center bg-tree87Green text-white hover:bg-opacity-50"
                >
                  <div>{WORDS.CONTACT_US.FORM.SUBMIT.LABEL}</div>
                  <img src={whatsappIcon} className="scale-50 hover:bg-opacity-50" alt="whatsapp icon"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="z-10 mt-10 mr-2 mb-10 flex flex-row justify-start text-white">
          <div className="flex flex-col justify-start">
            <div className="font-polin-bold" key="key1">{ WORDS.CONTACT_US.DATA.RIGHT.TITLE }</div>
            <div className="font-polin-light" key="key2">{ WORDS.CONTACT_US.DATA.RIGHT.NAME }</div>
            <div className="font-polin-light" key="key3">{ WORDS.CONTACT_US.DATA.RIGHT.STREET }</div>
            <div className="font-polin-light" key="key4">{ WORDS.CONTACT_US.DATA.RIGHT.ADDRESS }</div>
            <div className="font-polin-light" key="key5">{ WORDS.CONTACT_US.DATA.RIGHT.PHONE }</div>
          </div>
          <div className="flex flex-col justify-start"></div>
        </div>
        <div className="z-10 bg-tree87Green flex justify-center p-4 text-white">
          <div className="scale-70">{ WORDS.CONTACT_US.DATA.RIGHT.DESIGNED_BY }</div>
          <img src={designerLogo} alt="designer logo" className="scale-50"/>
        </div>
      </div>
    </section>
  );
}
