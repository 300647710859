import React, {useRef} from "react";

export default function AccordionItem({ handleToggle, active, messages }) {
  const contentEl = useRef();
  const { header, id, items } = messages;

  return (
    <div className="rc-accordion-card text-black">
      <div className="rc-accordion-header">
        <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
          <h5 className="rc-accordion-title text-white">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
        active === id
          ? { height: contentEl?.current?.scrollHeight }
          : { height: "0px" }
      }>
        <div className="rc-accordion-body">
          <ul>
            {
              items.map(message => (
                <li className="font-polin-light text-right" key={message}>- {message}</li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  )
}