import React from 'react';

export default function ProductA() {


  const aboutStyle = {
    height: '100vh',
    backgroundColor: 'yellow',
  }
  return (
    <div id="product_a" style={aboutStyle}>
      product A
    </div>
  )
}