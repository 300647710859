import React from 'react';
import {WORDS} from "../../dictionary/words";
import RoundBorderWithText from "../../components/RoundBorderWithText";
export default function Numerology2() {
  return (
    <div id="numerology2" className="flex items-center justify-center p-1 min-h-screen flex-wrap">
      {
        WORDS.NUMEROLOGY_2.MESSAGES.map(({ TITLE, MESSAGES }) => (
          <RoundBorderWithText radius={190}
                               title={TITLE}
                               messages={MESSAGES}
                               key={TITLE}
          />
        ))
      }
    </div>
  )
}