import React from 'react';
import tree87opaqueSymbol from '../../assets/images/tree87opaqueSymbol.png'
import {WORDS} from "../../dictionary/words";
export default function Numerology1() {
  return (
    <div id="numerology" className="bg-tree87Green flex items-center justify-center min-h-screen ">
      <div className="relative w-full max-w-4xl mx-auto p-6">
        <div className="flex justify-center items-center">
          <img src={tree87opaqueSymbol} alt="tree87 logo" className="md:scale-160"/>
        </div>
        <div className="absolute inset-0 flex flex-col justify-center items-center space-y-4 mt-10">
          <div className="text-center text-white">
            <h1 className="text-lg md:text-2xl font-bold font-polin-light">{WORDS.NUMEROLOGY_1.TITLE}</h1>
            <p className="text-2xl md:text-4xl font-lia-shamaim">{WORDS.NUMEROLOGY_1.SUBTITLE}</p>
          </div>
          <div className="flex w-full flex-col justify-between text-white px-4 text-6xl">
            <div className="w-full text-right m-2">
              <p className="text-lg font-lia-shamaim">{WORDS.NUMEROLOGY_1.RIGHT.TITLE}</p>
              <p className="text-base md:text-2xl font-polin-light">{WORDS.NUMEROLOGY_1.RIGHT.TEXT}</p>
            </div>
            <div className="w-full text-right m-2 mb-6">
              <p className="text-lg font-lia-shamaim">{WORDS.NUMEROLOGY_1.LEFT.TITLE}</p>
              <p className="text-base md:text-2xl font-polin-light">{WORDS.NUMEROLOGY_1.LEFT.TEXT}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
